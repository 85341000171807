// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// import "firebase/remote-config";
import "firebase/analytics";
import "firebase/remote-config";

// this is for analytics only
const firebaseConfig = {
  apiKey: "AIzaSyCQYaW1oKnqWSQxlglVkq8nEpkPE7UOsA8",
  authDomain: "my-project-1523938120250.firebaseapp.com",
  databaseURL: "https://my-project-1523938120250.firebaseio.com",
  projectId: "my-project-1523938120250",
  storageBucket: "my-project-1523938120250.appspot.com",
  messagingSenderId: "272765177112",
  appId: "1:272765177112:web:2843dce61aa68d6f7c226d",
  measurementId: "G-R2NZKG7V8K",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

let analytics = null;
let remoteConfig = null;

if (typeof window !== "undefined") {
  analytics = firebase.analytics();
  remoteConfig = firebase.remoteConfig();
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000, // Set the minimum fetch interval (e.g., 1 hour)
  };
  remoteConfig.defaultConfig = {
    // Set default values for your feature flags
    valentines_day: false,
  };
}

const logPurchase = (orderId, currency, total, tax) => {
  const totalValue = ((total || 0) * 0.01).toFixed(2);
  const taxValue = ((tax || 0) * 0.01).toFixed(2);
  analytics?.logEvent(firebase.analytics.EventName.PURCHASE, {
    transaction_id: orderId,
    currency: currency,
    value: totalValue,
    tax: taxValue,
  });
};

enum RewardEventName {
  REWARD_VIEWED = "reward_viewed",
}
const firebaseTrackRewardsOnReceipt = (type: string) => {
  analytics?.logEvent(RewardEventName.REWARD_VIEWED, {
    source: "receipt",
    reward_type: type,
  });
};

const getFeatureFlag = async (flagName) => {
  if (!remoteConfig) {
    return false;
  }
  await remoteConfig.fetchAndActivate(); // Fetch and activate the latest config
  return remoteConfig.getBoolean(flagName); // Get the value of the specified flag
};

export { analytics, firebase, logPurchase, getFeatureFlag, firebaseTrackRewardsOnReceipt };
