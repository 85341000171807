import classNames from "classnames";
import type { ButtonHTMLAttributes, ReactNode } from "react";
import Text from "../../atoms/Text/Text";

const variants = {
  primary: "bg-black text-white",
  secondary: "bg-transparent text-black border-[1px] border-black",
  secondarySolid: "bg-white text-black border-[1px] border-black",
  tertiary: "bg-transparent text-gray-600",
  text: "text-black",
} as const;

export type ButtonVariant = keyof typeof variants;
export const allVariants = Object.keys(variants) as ButtonVariant[];

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof variants;
  contentLeft?: ReactNode;
  contentRight?: ReactNode;
  block?: boolean;
  height?: number;
  ariaLabel?: string;
}

export default function Button({
  disabled = false,
  style,
  children,
  onClick,
  className = "",
  variant = "primary",
  contentLeft,
  contentRight,
  block,
  height,
  type,
  ariaLabel,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={classNames(
        `flex-nowrap inline-flex items-center rounded-full py-3 max-w-[400px]`,
        height ? `h-[${height}px]` : "min-h-[40px] h-[40px] sm:min-h-[50px] sm:h-[50px]",
        variants[variant],
        contentLeft ? "pl-[25px]" : "pl-[30px]",
        contentRight ? "pr-[25px]" : "pr-[30px]",
        contentLeft && contentRight ? "justify-between" : "justify-center",
        block ? "w-full" : "w-fit",
        disabled && variant !== "secondarySolid" && "opacity-20",
        disabled && variant === "secondarySolid" && "border-opacity-20",
        className,
      )}
      style={style}
      aria-label={ariaLabel}
    >
      {contentLeft && <span className="mr-2">{contentLeft}</span>}
      <Text
        as="span"
        variant="lineItem"
        className={classNames("text-base text-nowrap font-semibold w-full", {
          "opacity-20": disabled && variant === "secondarySolid",
        })}
      >
        {children}
      </Text>
      {contentRight && <span className="ml-2">{contentRight}</span>}
    </button>
  );
}
